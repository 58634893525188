import React, { useState, useRef } from "react";
import Layout from "../layout/Layout";
import { graphql } from "gatsby";
import Components from "../components/dynamic";
import ProductFilters from "../components/ProductFilters";
import useGetAllBanners from "../hooks/banners/useGetAllBanners";
import Breadcrumbs from "../components/common/breadcrumbs/Breadcrumbs";
import useSearch from "../hooks/searchspring/useSearch";
import ProductSortFilter from "../components/searchSpring/ProductSortFilter";
import ProductListing from "../components/products/ProductListing";
import Banner from "../components/banner/Banner";
import Pagination from "../components/common/Pagination";
import { SearchContext } from "../context/SearchContext";
import { isUserLoggedIn } from "../utils/auth";

function BrandPage({ data, location }) {
  const components = data.dynamicBrands.versions?.components || [];
  const type = data.dynamicBrands.versions?.type || "";
  const brand_name = data.dynamicBrands.name;
  const pageWrapperRef = useRef(null);
  const brandId = data.dynamicBrands.id;

  const user = isUserLoggedIn();

  const { top_banner, bottom_banner } = useGetAllBanners("brand_page", brandId);
  const [view, setProductView] = useState("grid");

  const brandPageFilter = {
    [`bgfilter.brand`]: brand_name,
  };

  const {
    filters,
    products,
    sortingOptions,
    isLoading,
    pagination,
    breadcrumbs,
    facets,
    filterSummary,
    handlePageChange,
    applyFilter,
    handlePriceChange,
    onPriceReset,
    onClearAllFilter,
    handleSortingOptionChange,
  } = useSearch(`brand`, brandPageFilter, pageWrapperRef);

  const { breadcrumbTitle } = breadcrumbs;
  const { currentPage, totalPages } = pagination;

  const contextValues = {
    filters,
    isLoading,
    products,
    facets,
    view,
    filterSummary,
    sortingOptions,
    setProductView,
    applyFilter,
    handlePriceChange,
    onPriceReset,
    onClearAllFilter,
    handleSortingOptionChange,
  };

  const CategoyToggle = () => {
    const bodyClass = document.body.classList;

    if (bodyClass.contains("category-toggle-active")) {
      bodyClass.remove("category-toggle-active");
    } else {
      document.body.classList.add("category-toggle-active");
    }
  };

  return (
    <SearchContext.Provider value={contextValues}>
      <div className="page-wrapper" ref={pageWrapperRef}>
        <div className="container">
          <Breadcrumbs location={location} title={breadcrumbTitle} />
        </div>

        <div className="container">
          <div className="row flex flex-wrap page-layout">
            <div className="mobile-title-col show-mobile">
              <h3 className="page-title page-title-mobile">{brand_name}</h3>
            </div>

            {user ? (
              <>
                <button
                  className="mobile-sidebar-toggle show-mobile"
                  onClick={() => CategoyToggle()}
                >
                  <span className="show-filter">Show Filter</span>
                  <span className="hide-filter">Hide Filter</span>
                </button>
              </>
            ) : null}

            <div className="col page-sidebar page-sidebar-toggle">
              <div className="page-sidebar-inner-wrap">
                <ProductFilters />
              </div>
            </div>
            <div className="col page-content">
              <Banner banner={top_banner} />
              {components.length
                ? components.map((item, index) => {
                    return (
                      <Components
                        data={item}
                        type={type}
                        key={`${item.code}-${index}`}
                      />
                    );
                  })
                : null}
              <div className="page-action-bar flex justify-space vertical-middle">
                <h1 className="page-title hide-mobile">{brand_name}</h1>

                <ProductSortFilter />
              </div>
              <div className="col page-content"></div>

              <ProductListing />
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={totalPages}
                onPageChange={(page) => handlePageChange(page)}
              />
              <Banner banner={bottom_banner} />
            </div>
          </div>
        </div>
      </div>
    </SearchContext.Provider>
  );
}

export const Head = ({ data }) => {
  const seoDetails = data.dynamicBrands.versions?.seo_details || {};
  return (
    <>
      <title>{seoDetails?.meta_title}</title>
      <meta name="description" content={seoDetails?.meta_description || ""} />
    </>
  );
};

export const query = graphql`
  query GetDynamicBrandsComponents($id: String!) {
    dynamicBrands(id: { eq: $id }) {
      id
      name
      url
      versions {
        type
        status
        components {
          code
          variant {
            config {
              ...dynamicBrandsVersionsComponentsVariantConfigFragment
            }
            admin_layout
          }
        }
        seo_details {
          page_name
          page_url
          meta_title
          meta_description
        }
      }
    }
  }

  fragment dynamicBrandsVersionsComponentsVariantConfigFragment on dynamicBrandsVersionsComponentsVariantConfig {
    side_images {
      image_url
      title
      visibility
      url
    }
    slider {
      image_url
      title
      visibility
      url
    }
    banners {
      image_url
      mobile_image_url
      title
      url
      visibility
    }
    logos {
      image_url
      id
      title
      url
      visibility
    }
    data
    image_url
    mobile_image_url
    title
    url
    visibility
  }
`;

BrandPage.Layout = Layout;
export default BrandPage;
