import React from "react";
import PropTypes from "prop-types";
import ParseHTML from "../parse-html/ParseHTML";

// This component is used to render the banner on the page...
function Banner({ banner }) {
  return <ParseHTML html={banner} className="page-banner" />;
}

Banner.prototype = {
  banner: PropTypes.string,
};

export default Banner;
